import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/email-signup"

const SubscribePage = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title
    const social = data.site.siteMetadata.social

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Subscribe" />

            <h3>Subscribe</h3>
            <p>
                Get email notifications for new posts.
                This is about one email per month, and it's easy to unsubscribe.
            </p>
            <EmailSignup />
            <p>
                You can also add {siteTitle} to your <a href='http://dean.dog/rss.xml'>RSS feed</a>, or follow me on <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>.
            </p>
        </Layout>
    )
}

export default SubscribePage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                social {
                    twitter
                }
            }
        }
    }
`