import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class EmailSignup extends React.Component {
    state = {
        email: null,
    }

    _handleSubmit = async (e) => {
        e.preventDefault()
        addToMailchimp(this.state.email)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`)

                if (result !== 'success') {
                    throw msg
                }
                alert(msg)
            })
            .catch(err => {
                if (err.includes('is already subscribed')) {
                    alert('This email is already subscribed')
                } else {
                    alert(err)
                }
            })
    }

    _handleInputChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    render() {
        return (
            <form name="subscribeForm"  onSubmit={this._handleSubmit}>
                <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={this._handleInputChange}
                />
                <button className="button" type="submit">
                    Subscribe
                </button>
            </form>
        );
    }
}
